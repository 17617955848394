import React from "react";
import NavLinks from "./NavLinks";
import classes from './Navbar.module.css';

const Navegacion = () =>{
	return(
			<nav className={classes.Navegacion}>
			<NavLinks />
		</nav>
	);
};

export default Navegacion;
