import React, { Component } from "react";
import Footer from "../../components/footer/Footer";
import Navbar from "../../components/navbar/Navbar";
import "./contacto.css";
import Swal from "sweetalert2";
import TimerMixin from "react-timer-mixin";
import axios from "axios";
// import { ContactMail } from "@mui/icons-material";
import { withTranslation } from 'react-i18next';

class Contacto extends Component {

  constructor() {
    super();
    this.state = {

      NPMBRE_REC: "",
      CORREO_REC: "",
      TELEFONO_REC: "",
      MENSAJE_REC: "",
      COD_EMPRESA_REC: "1",
      COD_SUCURSAL_REC: "1",

    };

    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }
  onChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  registro = (newCliente) => {
    return axios
      .post("http://localhost:3333/contacto/mensaje", {
        COD_EMPRESA: newCliente.COD_EMPRESA,
        COD_SUCURSAL: newCliente.COD_SUCURSAL,
        NOMBRE: newCliente.NOMBRE,
        CORREO: newCliente.CORREO,
        TELEFONO: newCliente.TELEFONO,
        MENSAJE: newCliente.MENSAJE,
        ADICIONADO_POR: newCliente.ADICIONADO_POR,

      })
      .then((response) => {

        var tiempo = "300";
        TimerMixin.setTimeout(() => {
          if (response.data.alerta === "error") {
            Swal.fire(
              this.props.t('swalContacto.title'),
              this.props.t('swal1.verifiqueDatos'),
              "error"
            );

          } else {
            Swal.fire(
              this.props.t('swalContacto.title'),
              this.props.t('swalContacto.mensajeEnviado'),
              "success"
            );
            this.setState({
              NPMBRE_REC: "",
              CORREO_REC: "",
              TELEFONO_REC: "",
              MENSAJE_REC: "",
            });
          }

        }, tiempo);
      })
      .then((res) => {
        return res;
      })
      .catch((err) => {
        console.log(err)
      });
  };

  onSubmit(e) {
    var tiempo = "600";
    if (this.state.NPMBRE_REC === "") {
      e.preventDefault();
      Swal.fire(
        this.props.t('swalContacto.title'),
        this.props.t('swal1.ingreseNombre'),
        "error"
      );
    } else if (this.state.TELEFONO_REC === "") {
      e.preventDefault();
      Swal.fire(this.props.t('swalContacto.title'), this.props.t('swal1.ingresarTelefono'), "error");
    }
    else if (this.state.CORREO_REC === "") {
      e.preventDefault();
      Swal.fire(this.props.t('swalContacto.title'), this.props.t('swalContacto.ingreseCorreo'), "error");
    }
    else if (this.state.MENSAJE_REC === "") {
      e.preventDefault();
      Swal.fire(this.props.t('swalContacto.title'), this.props.t('swalContacto.errorMensaje'), "error");
    } else {
      e.preventDefault();
      Swal.fire({
        title: this.props.t('swalContacto.enviarMensajePregunta'),
        showDenyButton: true,
        // showCancelButton: true,
        confirmButtonColor: '#008BD9',
        confirmButtonText: this.props.t('swal1.confirmBTN'),
        denyButtonText: this.props.t('swal1.denyBTN'),
      }).then((result) => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
          // e.preventDefault();
          TimerMixin.setTimeout(() => {
            let timerInterval
            Swal.fire({
              title: this.props.t('swalContacto.title'),
              html: this.props.t('swal1.enviandoDatos'),
              timer: 2000000,
              timerProgressBar: true,
              didOpen: () => {
                Swal.showLoading()
                try {
                  const b = Swal.getHtmlContainer().querySelector('b')
                  timerInterval = setInterval(() => {
                    b.textContent = Swal.getTimerLeft()
                  }, 2000000)
                } catch (error) {

                }
              },
              willClose: () => {
                clearInterval(timerInterval)
              }
            }).then((result) => {
              /* Read more about handling dismissals below */
              if (result.dismiss === Swal.DismissReason.timer) {
                console.log('I was closed by the timer')
              }
            })
            const newCliente = {
              COD_EMPRESA: this.state.COD_EMPRESA_REC,
              COD_SUCURSAL: this.state.COD_SUCURSAL_REC,
              NOMBRE: this.state.NPMBRE_REC,
              CORREO: this.state.CORREO_REC,
              TELEFONO: this.state.TELEFONO_REC,
              MENSAJE: this.state.MENSAJE_REC,
              ADICIONADO_POR: "WEB",
            };

            this.registro(newCliente).then((res) => { });

            this.setState({
              showReservar: false
            })

          }, tiempo);
          // Swal.fire('Registrado!', '', 'success')
        } else if (result.isDenied) {
          Swal.fire({
            title: "Registro cancelado",
            confirmButtonColor: '#008BD9',
          }, "Registro cancelado", "", "info");
        }
      });
    } // here else cerrare
  }

  render() {
    const { t } = this.props;
    return (
      <section className="contactoTodo">
        <Navbar />
        <article className="contactoContainer">
          <div className="contactoTextos">
            <h1 className="contactoTitulo">
              {t('contactPage.title')}
            </h1>
            <p className="contactoParrafo">
            </p>
          </div>
          <div className="contactoWraper">
            <form action="" className="formulario">

              <input type="NPMBRE_REC"
                name="NPMBRE_REC"
                value={this.state.NPMBRE_REC}
                onChange={this.onChange}
                autoComplete="off"
                className="formularioCampo"
                placeholder={t('contactPage.name')} />

              <input type="TELEFONO_REC"
                name="TELEFONO_REC"
                value={this.state.TELEFONO_REC}
                onChange={this.onChange}
                autoComplete="off"
                className="formularioCampo"
                placeholder={t('contactPage.cellphone')} />

              <input type="CORREO_REC"
                name="CORREO_REC"
                value={this.state.CORREO_REC}
                onChange={this.onChange}
                autoComplete="off"
                className="formularioCampo"
                placeholder={t('contactPage.email')} />

              <textarea type="MENSAJE_REC"
                name="MENSAJE_REC"
                value={this.state.MENSAJE_REC}
                onChange={this.onChange}
                autoComplete="off"
                className="formularioCampo textarea"
                placeholder={t('contactPage.message')} />

              <button
                onClick={this.onSubmit}
                typeof="submit"
                className="contactoBoton">
                {t('Book.send')}
              </button>
            </form>
          </div>
        </article>
        <Footer />
      </section>
    );
  };
};

;
export default withTranslation()(Contacto);
