import axios from "axios";
import Swal                 from "sweetalert2";

export const login = (user) => {
    return axios
      .post("http://localhost:3333/usuario/login", {
        USERNAME: user.USERNAME,
        PASSWORD: user.PASSWORD,
      })
      .then((res) => {
        localStorage.setItem("usertoken", res.data.token);

        if (res.data.token !== null){
          window.location.href = 'dashboard/users/single';
        }else{
          Swal.fire("Inicio De Sesion", "Usuario y/o Contraseña Incorrecto", "error");
        }
        return res.data;
      })
      .catch((err) => {
        Swal.fire("Inicio De Sesion", "Usuario y/o Contraseña Incorrecto", "error");
        console.log("Invalid username and password, " + err);
      });
  };


export const getUser = (Id_usuario) => {
  return axios
    .get(`http://localhost:3333/usuario/profile/${Id_usuario}`)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
};


