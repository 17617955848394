import React from "react";
import Footer from "../../components/footer/Footer";
import Navbar from "../../components/navbar/Navbar";
import "./buffet.css";
import { fotosDesayunos } from "../../assets/dataDesayunos";
import Galeria from "../../components/slideshows/Galeria";
import { useTranslation, withTranslation } from "react-i18next";

const Buffet = () => {
  const { t } = useTranslation();
  return (
    <div>
      <Navbar />
      <section className="hotelContainer">
        <article className="buffetWrapper">
          <h1 className="hotelTitleBuffet">
            {t("BreakfastPage.brkfstTitle")}
          </h1>
          <h1 className="hotelTitle">
            {t("BreakfastPage.brkfstSubTitle")}
          </h1>
          <Galeria images={fotosDesayunos} />
          {/*
          <div className="hotelDetails">
            <div className="hotelDetailsText">
              <p className="hotelDesc">
                Lorem cumque perspiciatis rem repellendus earum. Animi cum tempora delectus nemo dolor. Rerum natus sequi quis culpa laboriosam deleniti Natus exercitationem in debitis aliquid nisi? Explicabo asperiores sint omnis aperiam
              </p>
              <p className="hotelDesc">
                Lorem cumque perspiciatis rem repellendus earum. Animi cum tempora delectus nemo dolor. Rerum natus sequi quis culpa laboriosam deleniti Natus exercitationem in debitis aliquid nisi? Explicabo asperiores sint omnis aperiam
              </p>
              <p className="hotelDesc">
                Lorem cumque perspiciatis rem repellendus earum. Animi cum tempora delectus nemo dolor. Rerum natus sequi quis culpa laboriosam deleniti Natus exercitationem in debitis aliquid nisi? Explicabo asperiores sint omnis aperiam
</div>
              </p>
*/}
          {/*
<div className="hotelDetailsPrice">
<div className="siDetailsTexts">
<h1>Perfecto para una estadia lujosa</h1>
<h2>
<b className="siPrice">945 NIO</b> (familiar)
</h2>
<span className="siTaxOp">
Incluye iva
</span>
</div>
<div className="siServicios">
<div className="siServiciosLeft">
<p>
<FaWifi className="iconos" />
Wifi Gratis</p>
<p>
<FaSnowflake className="iconos" />
Aire Condicionado</p>
<p>
<FaShower className="iconos" />
Baño en la habitación</p>
</div>
<div className="siServiciosRight">
<p><FaParking className="iconos" />
Parqueo gratis</p>
<p><MdFreeBreakfast className="iconos" />
Desayuno</p>
<p><FaSmokingBan className="iconos" />
No Fumadores</p>
</div>
</div>
<button>Reserva Ahora</button>
</div>
</div>
<h1 className="homeTitle" style={{ paddingTop: "10px" }}> 
             Encuentra la habitación ideal 
           </h1> 
*/}
        </article>
        <Footer />
      </section>
    </div >
  );
};

export default withTranslation()(Buffet);
