import { React, useState } from 'react';
import NavegacionNew from './NavegacionNew';
import NavegacionMovilNew from './NavegacionMovil';
import { Link } from 'react-router-dom';
import './NavbarNew.css';
import logo from '../../media/images/logos/hotel-san-juan-travel-logo.png';

const NavbarNew = (props) => {

  //Cambiar el color del navbar al scroll
  const [color, setColor] = useState(false)
  const cambiarColor = () => {
    if (window.scrollY >= 10) {
      setColor(true)
    } else {
      setColor(false)
    }
  }

  window.addEventListener('scroll', cambiarColor)

  return (
    <header className={color ? 'Navbar NavbarColor' : 'Navbar'}>
      <Link to="/">
        <div className="logoHeader" >
          <img src={logo} className="logo" alt="Hotel San Juan" />
          {/* <h1>Hotel San Juan</h1> */}
        </div>
      </Link>
      <NavegacionNew />
      <NavegacionMovilNew />
      <div className="menu-bars" id="menu-bars">
      </div>
    </header>
  );
}

export default NavbarNew;
