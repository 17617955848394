import React, {useState} from "react";
import NavLinksNew from "./NavLinksNew";
import './NavbarNew.css';
import {FaBars} from 'react-icons/fa'
import {AiOutlineClose} from 'react-icons/ai';

const NavegacionMovilNew = () =>{

	const [open, setOpen] = useState(false);

	const hamburguerIcon = <FaBars className="Hamburguer" 
														size='40px' 
														onClick={() =>setOpen(!open)}/>
	const closeIcon = <AiOutlineClose className="Hamburguer" 
														size='40px' 
														onClick={() =>setOpen(!open)}/>

	const cerrarMenuMovil = () => setOpen(false);
	

	return(
			<nav className="NavegacionMovil">
				{ open ? closeIcon : hamburguerIcon }
				{ open && <NavLinksNew esMovil={true} cerrarMenuMovil={cerrarMenuMovil} />}
		</nav>
	);
};

export default NavegacionMovilNew;
