import { faCircleArrowLeft, faCircleArrowRight, faCircleXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";

const Galeria = ({ images }) => {

  const [slideNumber, setSlideNumber] = useState(0);
  const [open, setOpen] = useState(false);

  const handleOpen = (index) => {
    setSlideNumber(index);
    setOpen(true);
  }

  let cantidad = images.length
  console.log(cantidad);

  /*
  const handleMove = (direction) => {
    let newSliderNumber;

    if (direction === "left") {
      newSliderNumber = slideNumber === 0 ? 3 : slideNumber - 1
    } else {
      newSliderNumber = slideNumber === 3 ? 0 : slideNumber + 1
    }

    setSlideNumber(newSliderNumber);
  }
  */
  const handleMove = (step) => {
    let newSliderNumber;
    const size = images.length;
    newSliderNumber = (((slideNumber + step) % size) + size) % size;
    setSlideNumber(newSliderNumber);
  }

  return (
    <>
      {open &&
        <div className="slider">
          <FontAwesomeIcon
            icon={faCircleXmark}
            className="close"
            onClick={() => setOpen(false)}
          />
          <FontAwesomeIcon
            icon={faCircleArrowLeft}
            className="arrow"
            onClick={() => handleMove(-1)}
          />
          <div className="sliderWrapper">
            <img
              src={images[slideNumber].img}
              className="sliderImg"
              alt="" />
          </div>
          <FontAwesomeIcon
            icon={faCircleArrowRight}
            className="arrow"
            onClick={() => handleMove(1)}
          />
        </div>
      }

      <div className="hotelImages">
        {images.map((photo, index) => (
          <div className={cantidad <= 2 ? "hotelImgWrapper50" : "hotelImgWrapper33"} >
            <img
              onClick={() => handleOpen(index)}
              src={photo.img}
              className="hotelImg"
              alt=""
            />
          </div>
        ))}
      </div>
    </>
  )
};

export default Galeria;
