import React from "react";
import Navbar from "./../../components/navbar/Navbar";
import Ubicacion from "../../components/ubicacion/Ubicacion";
import Footer from "../../components/footer/Footer";
import "../home/home.scss";

const UbicacionPage = () => {

  return (
    <div className="homeContainer"
      style={{ marginTop: "-5px" }}>
      <Navbar />
      <Ubicacion />
      <Footer />
    </div>
  );
};

export default UbicacionPage;
