import { React } from 'react';
import Navegacion from './Navegacion';
import NavegacionMovil from './NavegacionMovil';
import { Link } from 'react-router-dom';
import classes from './Navbar.module.css'
import logo from '../../media/images/logos/hotel-san-juan-travel-logo.png';

const Navbar = (props) => {

  return (
    <header className={classes.Navbar}>
      <Link to="/" >
        <div className={classes.logoHeader} >
          <img src={logo} className="logo" alt="Hotel San Juan" />
          {/* <h1>Hotel San Juan</h1> */}
        </div>
      </Link>
      <Navegacion />
      <NavegacionMovil />
      <div className="menu-bars" id="menu-bars">
      </div>
    </header>
  );
}

export default Navbar;
