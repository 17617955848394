import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Link } from 'react-router-dom';
import { faBed, faPeopleGroup, faLocationDot, faUtensils } from "@fortawesome/free-solid-svg-icons";
import './NavbarNew.css';
import { useTranslation, withTranslation } from "react-i18next";
import LanguageBTNs from "../../translations/LangugeBTNs"

const NavLinksNew = (props) => {
  const { t } = useTranslation();

  return (
    <div className="listas">
      <div className="menuNew">
        <ul id="menuMew">
          <li onClick={() => props.esMovil && props.cerrarMenuMovil()}>
            <Link to="/rooms">
              <FontAwesomeIcon style={{ paddingRight: "5px" }} icon={faBed} />
              {t("navbar.lodgings")}
            </Link>
          </li>
          <li onClick={() => props.esMovil && props.cerrarMenuMovil()}>
            <Link to="/buffet">
              <FontAwesomeIcon style={{ paddingRight: "5px" }} icon={faUtensils} />
              {t("navbar.breakfasts")}
            </Link>
          </li>
          <li onClick={() => props.esMovil && props.cerrarMenuMovil()}>
            <Link to="/tourList">
              <FontAwesomeIcon style={{ paddingRight: "5px" }} icon={faPeopleGroup} />
              {t("navbar.tours")}
            </Link>
          </li>
          <li onClick={() => props.esMovil && props.cerrarMenuMovil()}>
            <Link to="/ubicacion" >
              <FontAwesomeIcon style={{ paddingRight: "5px" }} icon={faLocationDot} />
              {t("navbar.location")}
            </Link>
          </li>
        </ul>
      </div>
      <div className="menuNew2">
        <ul id="menu">

          <LanguageBTNs props={props} />

          <li className="contactto" onClick={() => props.esMovil && props.cerrarMenuMovil()}>
            <Link to="/contacto"><span className="estiloListaBoton">
              <b>
                {t("navbar.contact")}
              </b></span>
            </Link>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default withTranslation()(NavLinksNew);
