import React, { Component } from "react";
import { login } from "../../UserFunctions";
import Navbar from "../../components/navbar/Navbar";
import { Link } from "react-router-dom";
import "./login.css";

class Login extends Component {
  
	constructor() {
	super();
	this.state = {
		USERNAME : "",//POST
		PASSWORD : "",//POST
	err: {},
	};
	this.onChange = this.onChange.bind(this);
	this.onSubmit = this.onSubmit.bind(this);
	}
	
	onChange(e) {
	this.setState({ [e.target.name]: e.target.value });
	}
	
	onSubmit(e) {
	e.preventDefault();
	const user = {
	USERNAME : this.state.USERNAME,
	PASSWORD : this.state.PASSWORD,
	};
	login(user).then((res) => {

	});
	}
	
	  render() {
	return(
		<>
			<Navbar />
		<div className="loginContainer">
			<div className="square1">
			</div>
			<div className="square2">
			</div>
			<div className="square3">
			</div>
			<div className="square4">
			</div>
			<div className="square5">
			</div>
			<div className="square6">
			</div>
			<div className="loginWrap">
					<div className="loginTextos">
						<h1 className="loginTitulo">Inicia sesión</h1>
				<form action="" className="loginFormulario">
					<input type="USERNAME" className="loginFormularioCampo" placeholder="Usuario"  name="USERNAME"     value={this.state.USERNAME}    onChange={this.onChange}/>
					<input type="PASSWORD"className="loginFormularioCampo"  placeholder="Contraseña" name="PASSWORD"     value={this.state.PASSWORD}    onChange={this.onChange}/>
						<Link to="/dashboard/users/single">
					<button   className="loginContactoBoton" onClick={this.onSubmit}>
						Iniciar sesión
					</button>
						</Link>
				</form>
				
					</div>
			</div>
		</div>
		</>
    );
}
}
export default Login;
