// Matrimonial
import mat01 from "../media/images/MULTIMEDIA-HOTEL-SAN-JUAN-LEON/HABITACIONES/MATRIMONIAL/cardMatrimonial01.jpg";
import mat02 from "../media/images/MULTIMEDIA-HOTEL-SAN-JUAN-LEON/HABITACIONES/MATRIMONIAL/cardMatrimonial02.jpg";
import mat03 from "../media/images/MULTIMEDIA-HOTEL-SAN-JUAN-LEON/HABITACIONES/MATRIMONIAL/cardMatrimonial03.jpg";
import mat04 from "../media/images/MULTIMEDIA-HOTEL-SAN-JUAN-LEON/HABITACIONES/MATRIMONIAL/cardMatrimonial04.jpg";
// Unipersonal
import uni01 from "../media/images/MULTIMEDIA-HOTEL-SAN-JUAN-LEON/HABITACIONES/UNI-PERSONAL/cardUni01.jpg";
import uni02 from "../media/images/MULTIMEDIA-HOTEL-SAN-JUAN-LEON/HABITACIONES/UNI-PERSONAL/cardUni02.jpg";
import uni03 from "../media/images/MULTIMEDIA-HOTEL-SAN-JUAN-LEON/HABITACIONES/UNI-PERSONAL/cardUni03.jpg";
// Doble
import doble01 from "../media/images/MULTIMEDIA-HOTEL-SAN-JUAN-LEON/HABITACIONES/DOBLE/doble01.jpg";
import doble02 from "../media/images/MULTIMEDIA-HOTEL-SAN-JUAN-LEON/HABITACIONES/DOBLE/doble02.jpg";
// Triple
import triple01 from "../media/images/MULTIMEDIA-HOTEL-SAN-JUAN-LEON/HABITACIONES/TRIPLE/triple01.jpg";
import triple02 from "../media/images/MULTIMEDIA-HOTEL-SAN-JUAN-LEON/HABITACIONES/TRIPLE/triple02.jpg";
import triple03 from "../media/images/MULTIMEDIA-HOTEL-SAN-JUAN-LEON/HABITACIONES/TRIPLE/triple03.jpg";
import triple04 from "../media/images/MULTIMEDIA-HOTEL-SAN-JUAN-LEON/HABITACIONES/TRIPLE/triple04.jpg";
// Cuadruple
import cuad01 from "../media/images/MULTIMEDIA-HOTEL-SAN-JUAN-LEON/HABITACIONES/CUADRUPLE/cuad00.jpg";
import cuad02 from "../media/images/MULTIMEDIA-HOTEL-SAN-JUAN-LEON/HABITACIONES/CUADRUPLE/cuad01.jpg";
import cuad03 from "../media/images/MULTIMEDIA-HOTEL-SAN-JUAN-LEON/HABITACIONES/CUADRUPLE/cuad02.jpg";
import cuad04 from "../media/images/MULTIMEDIA-HOTEL-SAN-JUAN-LEON/HABITACIONES/CUADRUPLE/cuad03.jpg";
import cuad05 from "../media/images/MULTIMEDIA-HOTEL-SAN-JUAN-LEON/HABITACIONES/CUADRUPLE/cuad04.jpg";


const fotosMatrimonial = [
  {
    id: 1,
    img: mat01
  },
  {
    id: 2,
    img: mat02
  },
  {
    id: 3,
    img: mat03
  },
  {
    id: 4,
    img: mat04
  },
]

const fotosUnipersonal = [
  {
    id: 1,
    img: uni01
  },
  {
    id: 2,
    img: uni02
  },
  {
    id: 3,
    img: uni03
  },
]

const fotosDoble = [
  {
    id: 1,
    img: doble01
  },
  {
    id: 2,
    img: doble02
  },
]

const fotosTriple = [
  {
    id: 1,
    img: triple01
  },
  {
    id: 2,
    img: triple02
  },
]

const fotosTriple2 = [
  {
    id: 1,
    img: triple03
  },
  {
    id: 2,
    img: triple04
  },
]

const fotosCuadruple = [
  {
    id: 1,
    img: cuad01
  },
  {
    id: 2,
    img: cuad02
  },
  {
    id: 3,
    img: cuad03
  },
  {
    id: 4,
    img: cuad04
  },
  {
    id: 5,
    img: cuad05
  },
]

export { fotosMatrimonial, fotosUnipersonal, fotosDoble, fotosTriple, fotosTriple2, fotosCuadruple };
