// import img0 from '../../media/images/MULTIMEDIA-HOTEL-SAN-JUAN-LEON/DESAYUNO/desayuno00-min.jpg';
// import img5 from '../../media/images/MULTIMEDIA-HOTEL-SAN-JUAN-LEON/DESAYUNO/desayuno05-min.jpg';
import img6 from '../media/images/MULTIMEDIA-HOTEL-SAN-JUAN-LEON/DESAYUNO/desayuno06-min.jpg';
import img1 from '../media/images/MULTIMEDIA-HOTEL-SAN-JUAN-LEON/DESAYUNO/desayuno01-min.jpg';
import img2 from '../media/images/MULTIMEDIA-HOTEL-SAN-JUAN-LEON/DESAYUNO/desayuno02-min.jpg';
import img3 from '../media/images/MULTIMEDIA-HOTEL-SAN-JUAN-LEON/DESAYUNO/desayuno03-min.jpg';
import img4 from '../media/images/MULTIMEDIA-HOTEL-SAN-JUAN-LEON/DESAYUNO/desayuno04-min.jpg';

export const fotosDesayunos = [
  {
    id: 1,
    img: img6
  },
  {
    id: 2,
    img: img1
  },
  {
    id: 3,
    img: img2
  },
  {
    id: 4,
    img: img3
  },
  {
    id: 5,
    img: img4
  },
]
  /*
{dataDigitalBestSeller.map((item) =>(
<div classname="card">
<div classname="card-top">
<img src={item.src} alt="" />
</div>
<div classname="card-bottom">
</div>
</div>
))}

*/
