import { faCircleArrowLeft, faCircleArrowRight, faCircleXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import "../searchItem/SearchItem";
import "../cuartosCards/cuartosCards.css";

const Slideshow = ({ images, size }) => {

  const [slideNumber, setSlideNumber] = useState(0);
  const [open, setOpen] = useState(false);

  const handleOpen = (index) => {
    // console.log("clicked")
    setSlideNumber(index);
    setOpen(true);
  }

  const handleMove = (step) => {
    let newSliderNumber;
    const size = images.length;
    newSliderNumber = (((slideNumber + step) % size) + size) % size;
    setSlideNumber(newSliderNumber);
  }

  return (
    <>
      {open &&
        <div className="slider">
          <FontAwesomeIcon
            icon={faCircleXmark}
            className="close"
            onClick={() => setOpen(false)}
          />
          <FontAwesomeIcon
            icon={faCircleArrowLeft}
            className="arrow"
            onClick={() => handleMove(-1)}
          />
          <div className="sliderWrapper" >
            <img
              src={images[slideNumber].img}
              className="sliderImg"
              alt="" />
          </div>
          <FontAwesomeIcon
            icon={faCircleArrowRight}
            className="arrow"
            onClick={() => handleMove(1)}
          />
        </div>
      }

      <div className="sliderCards">
        <FontAwesomeIcon
          icon={faCircleArrowLeft}
          className="arrowCards"
          onClick={() => handleMove(-1)}
        />
        <div className={size !== "grande" ? "sliderWrapperCards" : "sliderWrapperCards600"}>
          <img
            onClick={() => handleOpen(slideNumber)}
            src={images[slideNumber].img}
            className="sliderImgCards"
            alt="Habitaciones" />
        </div>
        <FontAwesomeIcon
          icon={faCircleArrowRight}
          className="arrowCards"
          onClick={() => handleMove(1)}
        />
      </div>
    </>
  )
}

export default Slideshow;
