// import {faCircleArrowLeft, faCircleArrowRight, faCircleXmark } from "@fortawesome/free-solid-svg-icons";
// import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
// import { FaRegClock } from "react-icons/fa";
// import { MdPets, MdLanguage } from "react-icons/md";
//import { MdFreeBreakfast } from "react-icons/md";
//import { AiOutlineWarning } from "react-icons/ai";
import React, { Component } from "react";
import Footer from "../../components/footer/Footer";
import Navbar from "../../components/navbar/Navbar";
// import FeaturedTurismo from "../../components/featuredTurismo/FeaturedTurismo";
import Galeria from "../../components/slideshows/Galeria";
import { fotosTurismo } from "./dataTurismo";
import "./tours.css";
import { withTranslation } from 'react-i18next';
import { BsCheck } from 'react-icons/bs';

import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import es from "date-fns/locale/es";
import Swal from "sweetalert2";
import TimerMixin from "react-timer-mixin";
import axios from "axios";
// import moment from "moment-timezone";
registerLocale("es", es);

class Tours extends Component {

  constructor() {
    super();
    this.state = {

      NPMBRE_REC: "",
      CORREO_REC: "",
      TELEFONO_REC: "",
      MENSAJE_REC: "",
      NUMERO_PERS_REC: "",
      COD_EMPRESA_REC: "1",
      COD_SUCURSAL_REC: "1",
      TIPO_RESERVA: "2",
      RESERVA_REC: "6",



      fecha_1: new Date(),//GET
      HORA: new Date(),//GET

      HOY: new Date(),
    };
    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.reestriccion_telefono = this.reestriccion_telefono.bind(this);
    this.toggleAbrirReservar = this.toggleAbrirReservar.bind(this)
    this.cerrarReservar = this.cerrarReservar.bind(this)

  }
  onChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }


  onChange2 = (fecha_1_1) => {
    this.setState({ fecha_1: fecha_1_1 });
  };

  onChange4 = (hora1) => {
    this.setState({ HORA: hora1 });
  };

  toggleAbrirReservar(event) {
    event.preventDefault()
    this.setState({
      showReservar: !this.state.showReservar
    })
  }
  cerrarReservar(event) {
    event.preventDefault()
    this.setState({
      showReservar: false
    })

  }



  registro = (newCliente) => {
    return axios
      .post("http://localhost:3333/reserva/registro", {
        COD_EMPRESA: newCliente.COD_EMPRESA,
        COD_SUCURSAL: newCliente.COD_SUCURSAL,
        NOMBRE: newCliente.NOMBRE,
        CORREO: newCliente.CORREO,
        TELEFONO: newCliente.TELEFONO,
        CHECK_INT: newCliente.CHECK_INT,
        CHECK_OUT: newCliente.CHECK_OUT,
        HORA: newCliente.HORA,
        NO_PERSONAS: newCliente.NO_PERSONAS,
        MENSAJE: newCliente.MENSAJE,
        TIPO_RESERVA: newCliente.TIPO_RESERVA,
        RESERVA: newCliente.RESERVA,
        ADICIONADO_POR: newCliente.ADICIONADO_POR,

      })
      .then((response) => {

        var tiempo = "3000";
        TimerMixin.setTimeout(() => {
          if (response.data.alerta === "error") {

            Swal.fire(
              this.props.t('swal1.registroReserva'),
              this.props.t('swal1.verifiqueDatos'),
              "error"
            );

          } else {
            Swal.fire(
              this.props.t('swal1.registroReserva'),
              this.props.t('swal1.registroSatisfactorio'),
              "success"
            );
            this.setState({
              NPMBRE_REC: "",
              CORREO_REC: "",
              TELEFONO_REC: "",
              fecha_1: new Date(),
              fecha_2: new Date(),
              HORA: new Date(),
              MENSAJE_REC: "",
              NUMERO_PERS_REC: "",

            })
          }

        }, tiempo);
      })
      .then((res) => {
        return res;
      })
      .catch((err) => {
        console.log(err)
      });
  };

  onSubmit(e) {
    var tiempo = "600";
    if (this.state.NPMBRE_REC === "") {
      e.preventDefault();
      Swal.fire(
        this.props.t('swal1.registroReserva'),
        this.props.t('swal1.ingreseNombre'),
        "error"
      );
    }
    else if (this.state.CORREO_REC === "") {
      e.preventDefault();
      Swal.fire(this.props.t('swal1.registroReserva'), this.props.t('swal1.ingresarCorreo'), "error");
    }
    else if (this.state.TELEFONO_REC === "") {
      e.preventDefault();
      Swal.fire(this.props.t('swal1.registroReserva'), this.props.t('swal1.ingresarTelefono'), "error");
    } else if (this.state.NUMERO_PERS_REC === "") {
      e.preventDefault();
      Swal.fire(this.props.t('swal1.registroReserva'), this.props.t('swal1.ingresaarPersonas'), "error");
    } else if (this.state.fecha_1 === null) {
      e.preventDefault();
      Swal.fire(
        this.props.t('swal1.registroReserva'),
        this.props.t('swal1.verifiqueCheckIn'),
        "error"
      );
    } else if (this.state.HORA === null) {
      e.preventDefault();
      Swal.fire(
        this.props.t('swal1.registroReserva'),
        this.props.t('swal1.verifiqueHoraLLegada'),
        "error"
      );
    } else {
      e.preventDefault();
      Swal.fire({
        title: this.props.t('swal1.realizarReserva'),
        showDenyButton: true,
        // showCancelButton: true,
        confirmButtonText: this.props.t('swal1.confirmBTN'),
        denyButtonText: this.props.t('swal1.denyBTN'),
      }).then((result) => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
          // e.preventDefault();
          TimerMixin.setTimeout(() => {
            let timerInterval
            Swal.fire({
              title: this.props.t('swal1.registroReserva'),
              html: this.props.t('swal1.enviandoDatos'),
              timer: 2000000,
              timerProgressBar: true,
              didOpen: () => {
                Swal.showLoading()
                try {
                  const b = Swal.getHtmlContainer().querySelector('b')
                  timerInterval = setInterval(() => {
                    b.textContent = Swal.getTimerLeft()
                  }, 2000000)
                } catch (error) {

                }
              },
              willClose: () => {
                clearInterval(timerInterval)
              }
            }).then((result) => {
              /* Read more about handling dismissals below */
              if (result.dismiss === Swal.DismissReason.timer) {
                console.log('I was closed by the timer')
              }
            })
            const newCliente = {
              COD_EMPRESA: this.state.COD_EMPRESA_REC,
              COD_SUCURSAL: this.state.COD_SUCURSAL_REC,
              NOMBRE: this.state.NPMBRE_REC,
              CORREO: this.state.CORREO_REC,
              TELEFONO: this.state.TELEFONO_REC,
              CHECK_INT: this.state.fecha_1,
              CHECK_OUT: this.state.fecha_1,
              HORA: this.state.HORA,
              NO_PERSONAS: this.state.NUMERO_PERS_REC,
              MENSAJE: this.state.MENSAJE_REC,
              TIPO_RESERVA: this.state.TIPO_RESERVA,
              RESERVA: this.state.RESERVA_REC,
              ADICIONADO_POR: "HUESPED",
            };

            this.registro(newCliente).then((res) => { });

            this.setState({
              showReservar: false
            })

          }, tiempo);
          // Swal.fire('Registrado!', '', 'success')
        } else if (result.isDenied) {
          Swal.fire(this.props.t('swal1.registroCancelado'), "", "info");
        }
      });
    } // here else cerrare
  }

  reestriccion_telefono(e) {
    if (this.state.TELEFONO_REC !== "") {
      if (this.state.TELEFONO_REC.length < 8) {
        e.preventDefault();
        Swal.fire(
          this.props.t('swal1.registroReserva'),
          this.props.t('swal1.errorNumero'),
          "error"
        );
      } else if (this.state.TELEFONO_REC.length > 8) {
        e.preventDefault();
        Swal.fire(
          this.props.t('swal1.registroReserva'),
          this.props.t('swal1.errorNumero'),
          "error"
        );
      }
    }
  }
  render() {

    const { showReservar } = this.state

    const { t } = this.props;
    return (
      <div>
        <Navbar />
        <div className="tourContainer">
          {/* {open &&
				<div className="slider">
					<FontAwesomeIcon 
						icon={faCircleXmark} 
						className="close" 
						onClick={()=>setOpen(false)} 
					/>
					<FontAwesomeIcon 
						icon={faCircleArrowLeft} 
						className="arrow" 
						onClick={()=>handleMove("left")}
					/>
					<div className="sliderWrapper">
						<img src={photos[slideNumber].src} className="sliderImg" alt="" />
					</div>
					<FontAwesomeIcon 
						icon={faCircleArrowRight} 
						className="arrow"
						onClick={()=>handleMove("right")}
					/>
				</div>
				} */}

          {showReservar === true ?
            <div className="reservarContainer" >
              <div className="reservarWrapper">

                <h2
                  className="reservaMainTitle"
                  htmlFor="NPMBRE_REC">
                  {t('Book.bookNow')}
                </h2>
                <form className="formReservas">

                  <div
                    htmlFor="NPMBRE_REC"
                    className="formInput">
                    <label>
                      {t('Book.fullName')}
                    </label>
                    <input
                      type="NPMBRE_REC"
                      name="NPMBRE_REC"
                      value={this.state.NPMBRE_REC}
                      onChange={this.onChange}
                      autoComplete="off"
                    />
                  </div>

                  <div
                    htmlFor="CORREO_REC"
                    className="formInput">
                    <label>
                      {t('Book.email')}
                    </label>
                    <input
                      type="CORREO_REC"
                      name="CORREO_REC"
                      value={this.state.CORREO_REC}
                      onChange={this.onChange}
                      autoComplete="off"
                    />
                  </div>

                  <div
                    htmlFor="TELEFONO_REC"
                    className="formInput">
                    <label>
                      {t('Book.phone')}
                    </label>
                    <input
                      type="TELEFONO_REC"
                      name="TELEFONO_REC"
                      value={this.state.TELEFONO_REC}
                      onChange={this.onChange}
                      // onBlur={this.reestriccion_telefono}
                      autoComplete="off"
                    />
                  </div>

                  <div
                    className="formInput"
                    htmlFor="NUMERO_PERS_REC">
                    <label>
                      {t('Book.people')}
                    </label>
                    <select
                      name="NUMERO_PERS_REC"
                      value={this.state.NUMERO_PERS_REC}
                      onChange={this.onChange}
                    >
                      <option></option>
                      <option>1</option>
                      <option>2</option>
                      <option>3</option>
                      <option>4</option>
                      <option>5</option>
                      <option>6</option>
                      <option>7</option>
                      <option>8</option>
                      <option>9</option>
                      <option>10</option>
                      <option>11</option>
                      <option>12</option>
                      <option>13</option>
                      <option>14</option>
                      <option>15</option>
                      <option>16</option>
                      <option>17</option>
                      <option>18</option>
                      <option>19</option>
                      <option>20</option>
                      <option>21</option>
                      <option>22</option>
                      <option>23</option>
                      <option>24</option>
                      <option>25</option>
                      <option>26</option>
                      <option>27</option>
                      <option>28</option>
                      <option>29</option>
                      <option>30</option>
                      <option>31</option>
                      <option>32</option>
                      <option>33</option>
                      <option>34</option>
                      <option>35</option>
                      <option>36</option>
                      <option>37</option>
                      <option>38</option>
                      <option>39</option>
                      <option>40</option>
                      <option>41</option>
                      <option>42</option>
                      <option>43</option>
                      <option>44</option>
                      <option>45</option>
                      <option>46</option>
                      <option>47</option>
                      <option>48</option>
                      <option>49</option>
                      <option>50</option>
                      <option>51</option>
                      <option>52</option>
                      <option>53</option>
                      <option>54</option>
                      <option>55</option>
                      <option>56</option>
                      <option>57</option>
                      <option>58</option>
                      <option>59</option>
                      <option>60</option>
                      <option>61</option>
                      <option>62</option>
                      <option>63</option>
                      <option>64</option>
                      <option>65</option>
                      <option>66</option>
                      <option>67</option>
                      <option>68</option>
                      <option>69</option>
                      <option>70</option>
                      <option>71</option>
                      <option>72</option>
                      <option>73</option>
                      <option>74</option>
                      <option>75</option>
                      <option>76</option>
                      <option>77</option>
                      <option>78</option>
                      <option>79</option>
                      <option>80</option>
                    </select>
                  </div>


                  <div
                    htmlFor="fecha_1"
                    className="formInput">
                    <label>
                      {t('Book.chekin')}
                    </label>
                    <DatePicker
                      minDate={this.state.HOY}
                      selected={this.state.fecha_1}
                      onChange={this.onChange2}
                      locale="es"
                      className="formInputFecha"
                      dateFormat="dd '/' M '/' yyyy"
                    />
                  </div>
                  <div
                    htmlFor="fecha_1"
                    className="formInput">
                    <label>
                      {t('Book.time')}
                    </label>
                    <DatePicker
                      className="formInputFecha"
                      selected={this.state.HORA}
                      onChange={this.onChange4}
                      showTimeSelect
                      showTimeSelectOnly
                      timeIntervals={15}
                      timeCaption="Time"
                      dateFormat="h:mm aa"
                    />
                  </div>

                  <div
                    className="formInput"
                    htmlFor="MENSAJE_REC">
                    <label>
                      {t('Book.message')}
                    </label>
                    <textarea
                      style={{ width: '100%' }}
                      type="MENSAJE_REC"
                      name="MENSAJE_REC"
                      value={this.state.MENSAJE_REC}
                      onChange={this.onChange}
                      autoComplete="off"
                    />
                  </div>

                  <div className="formBtns">
                    <button
                      type="button"
                      className="btnReservaYaAceptar"
                      onClick={this.onSubmit}>
                      {t('Book.send')}
                    </button>
                    <button
                      type="button"
                      className="btnReservaYaCancelar"
                      onClick={this.cerrarReservar}>
                      {t('Book.cancel')}
                    </button>
                  </div>

                </form>

              </div>
            </div>
            : null}


          <div className="tourWrapper">
            <div className="insideBar">
              <h1 className="tourTitle">
                {t('TourPage.Title')}
              </h1>
            </div>
            {/*
            <div className="tourAddress">
              <span>Lorem Ipsum Dolor ab fugiat dignissimos consectetur nisi.</span>
            </div>
            <span className="tourDistance">
              Excelente lugar 500mts del centro
            </span>
            <span className="tourPriceHighlight">
            </span>
*/}
            <div className="tourImages">
              {/*
{photos.map((photo,i)=>(
							<div class="tourImgWrapper">
								<img 
									onClick={()=>handleOpen(i)}
									src={photo.src} 
									className="tourImg" 
									alt="" 
								/>
							</div>
						))} */}
            </div>
            <div className="tourDetails">
              <div className="tourDetailsText">
                {/* <FeaturedTurismo /> */}
                <Galeria images={fotosTurismo} />

                {/*
                <p className="tourDesc">
                  Lorem cumque perspiciatis rem repellendus earum. Animi cum tempora delectus nemo dolor. Rerum natus sequi quis culpa laboriosam deleniti Natus exercitationem in debitis aliquid nisi? Explicabo asperiores sint omnis aperiam
                </p>
                <p className="tourDesc">
                  Lorem cumque perspiciatis rem repellendus earum. Animi cum tempora delectus nemo dolor. Rerum natus sequi quis culpa laboriosam deleniti Natus exercitationem in debitis aliquid nisi? Explicabo asperiores sint omnis aperiam
                </p>
                <p className="tourDesc">
                  Lorem cumque perspiciatis rem repellendus earum. Animi cum tempora delectus nemo dolor. Rerum natus sequi quis culpa laboriosam deleniti Natus exercitationem in debitis aliquid nisi? Explicabo asperiores sint omnis aperiam
                </p>
*/}
              </div>

              <div className="hotelDetailsRight">
                    {/*
                <div className="tourDetailsPrice">
                  <div className="siDetailsTexts">
                    <h1>
                      {t('TourPageDetails.title')}
                    </h1>
								<h2>
									<b className="siPrice">945 NIO</b> 
								</h2>
                  </div>
                  <div className="siServicios">
                    <div className="siServiciosLeft">
                      <p>
                        <FaRegClock className="iconos" />
                        {t('TourPageDetails.time')}
                      </p>
                      <p>
                        <MdLanguage className="iconos" />
                        {t('TourPageDetails.language')}
                      </p>
                      <p><MdPets className="iconos" />
                        {t('TourPageDetails.pets')}
                      </p>
                    </div>
                  </div>
                </div>
								*/}

                <div className="reservaYa">
                  <button
                    onClick={this.toggleAbrirReservar}
                    className="btnReservaYa">
                    {t('Book.bookNow')}
                  </button>
                </div>

              </div>
            </div>

            <div className="addInfo">
              <h2 className="addInfoh2">
                {t('TourPage.Title2')}
              </h2>
              <div className="addInfoLeft">
                <span className="addInfoTitle">
                  {t('TourPageInfo.infoTitle')}
                </span>
                <ul className="addInfoList">
                  <li>
                    <BsCheck className="listCheck" />
                    {t('TourPageIncludeList.item1')}
                  </li>
                  <li>
                    <BsCheck className="listCheck" />
                    {t('TourPageIncludeList.item2')}
                  </li>
                  <li>
                    <BsCheck className="listCheck" />
                    {t('TourPageIncludeList.item3')}
                  </li>
                  <li>
                    <BsCheck className="listCheck" />
                    {t('TourPageIncludeList.item4')}
                  </li>
                  <li>
                    <BsCheck className="listCheck" />
                    {t('TourPageIncludeList.item5')}
                  </li>
                  <li>
                    <BsCheck className="listCheck" />
                    {t('TourPageIncludeList.item6')}
                  </li>
                  <li>
                    <BsCheck className="listCheck" />
                    {t('TourPageIncludeList.item7')}
                  </li>
                  <li>
                    <BsCheck className="listCheck" />
                    {t('TourPageIncludeList.item8')}
                  </li>
                </ul>
                {/*
                <span className="addInfoTitle">
                  {t('TourPageInfo.infoTitle2')}
                </span>
                <ul className="addInfoList">
                  <li>
                    {t('TourPageNOTIncludeList.item1')}
                  </li>
                </ul>
*/}
              </div>

              {/*
              <div className="addInfoRight">
                <span className="addInfoTitle">
                  {t('TourPageInfo.infoTitle3')}
                </span>
                <span className="addInfoDesc">
                  4 hrs
                </span>
                <span className="addInfoTitle">
                  {t('TourPageInfo.infoTitle4')}
                </span>
                <p className="addInfoDesc">
                  {t('TourPageDeparture.text1')}
                  <br /><br />
                  {t('TourPageDeparture.text2')}
                </p>
              </div>
*/}
            </div>
            {/*
            <h2 className="otrosTitle">
              Tours en la zona de León
            </h2>
*/}
          </div>
          <Footer />
        </div>
      </div>
    );
  }
}
;
export default withTranslation()(Tours);
