import React from "react";
import NavLinksNew from "./NavLinksNew";
import './NavbarNew.css';

const NavegacionNew = () =>{
	return(
			<nav className="Navegacion">
			<NavLinksNew />
		</nav>
	);
};

export default NavegacionNew;
