import React from "react";
import { useTranslation, withTranslation } from "react-i18next";

const LanguageBTNs = (props) => {

  const { i18n } = useTranslation();

  const changeLanguage = (language) => {
    i18n.changeLanguage(language);
  }
  return (


    <li className="showDropdown" onClick={() => props.esMovil && props.cerrarMenuMovil()}>
      <button className="btnLang" onClick={() => changeLanguage("en")}>
        {/* <FontAwesomeIcon style={{ paddingRight: "5px" }} icon={faGlobe} /> */}
        ENG
      </button>

      <span className="separadorLang">|</span>

      <button className="btnLang" onClick={() => changeLanguage("es")}>
        {/* <FontAwesomeIcon style={{ paddingRight: "5px" }} icon={faGlobe} /> */}
        ESP
      </button>
    </li>
  );
};

export default withTranslation()(LanguageBTNs);
