import React, {useState} from "react";
import NavLinks from "./NavLinks";
import classes from './Navbar.module.css';
import {FaBars} from 'react-icons/fa'
import {AiOutlineClose} from 'react-icons/ai';

const NavegacionMovil = () =>{

	const [open, setOpen] = useState(false);

	const hamburguerIcon = <FaBars className={classes.Hamburguer} 
														size='40px' 
														onClick={() =>setOpen(!open)}/>
	const closeIcon = <AiOutlineClose className={classes.Hamburguer} 
														size='40px' 
														onClick={() =>setOpen(!open)}/>

	const cerrarMenuMovil = () => setOpen(false);
	

	return(
			<nav className={classes.NavegacionMovil}>
				{ open ? closeIcon : hamburguerIcon }
				{ open && <NavLinks esMovil={true} cerrarMenuMovil={cerrarMenuMovil} />}
		</nav>
	);
};

export default NavegacionMovil;
