// Volcano Boarding
import volc01 from '../media/images/MULTIMEDIA-HOTEL-SAN-JUAN-LEON/TURISMO/volcano_boarding_1.jpeg';
import volc02 from '../media/images/MULTIMEDIA-HOTEL-SAN-JUAN-LEON/TURISMO/volcano_boarding_2.jpeg';
import volc03 from '../media/images/MULTIMEDIA-HOTEL-SAN-JUAN-LEON/TURISMO/volcano_boarding_3.jpeg';
import volc04 from '../media/images/MULTIMEDIA-HOTEL-SAN-JUAN-LEON/TURISMO/volcano_boarding_4.jpeg';
// Tour ciudad de Leon
import leonC01 from '../media/images/MULTIMEDIA-HOTEL-SAN-JUAN-LEON/TURISMO/leon_city_01.jpg';
import leonC02 from '../media/images/MULTIMEDIA-HOTEL-SAN-JUAN-LEON/TURISMO/leon_city_02.jpg';
import leonC03 from '../media/images/MULTIMEDIA-HOTEL-SAN-JUAN-LEON/TURISMO/leon_city_03.jpg';
// Tour Laguna Asosoca
import asos01 from '../media/images/MULTIMEDIA-HOTEL-SAN-JUAN-LEON/TURISMO/asososca_00.jpg';
import asos02 from '../media/images/MULTIMEDIA-HOTEL-SAN-JUAN-LEON/TURISMO/asososca_01.jpg';
import asos03 from '../media/images/MULTIMEDIA-HOTEL-SAN-JUAN-LEON/TURISMO/asososca_02.jpg';
// Tour Volcan Telica
import telik01 from '../media/images/MULTIMEDIA-HOTEL-SAN-JUAN-LEON/TURISMO/Telica_volcan_01.jpg';
import telik02 from '../media/images/MULTIMEDIA-HOTEL-SAN-JUAN-LEON/TURISMO/Telica_volcan_02.jpg';

const fotosVolcanoBoarding = [
  {
    id: 1,
    img: volc02
  },
  {
    id: 2,
    img: volc01,
  },
  {
    id: 3,
    img: volc03,
  },
  {
    id: 4,
    img: volc04
  },
];

const fotosLeonCity = [
  {
    id: 1,
    img: leonC01,
  },
  {
    id: 2,
    img: leonC02,
  },
  {
    id: 3,
    img: leonC03
  }
];

const fotosLagunaAsososca = [
  {
    id: 1,
    img: asos01,
  },
  {
    id: 2,
    img: asos02,
  },
  {
    id: 3,
    img: asos03
  }
];

const fotosVolcanTelica = [
  {
    id: 1,
    img: telik01,
  },
  {
    id: 2,
    img: telik02
  },
];
export { fotosVolcanoBoarding, fotosLeonCity, fotosLagunaAsososca, fotosVolcanTelica };
