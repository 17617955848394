import React from "react";
import Navbar from "./../../components/navbar/Navbar";
import '../hotelList/hotelList.css';
//import {useLocation} from "react-router-dom";
// import { format } from "date-fns";
// import { DateRange } from "react-date-range";
import Footer from "../../components/footer/Footer";
import SearchTours from "../../components/searchTours/SearchTours";
import VolcanoBoardingCard from '../../components/toursCards/volcanoBoarding';
// import LeonTourCity from '../../components/toursCards/LeonTourCity';
import CerroNegroAsososcaTour from '../../components/toursCards/CerroNegroAsososcaTour';
import TelicaVolcanTour from '../../components/toursCards/TelicaVolcanTour';


const ToursList = () => {

  /*
  const location = useLocation();
  const [destino, setDestino] = useState(location.state.destino);
  const [date, setDate] = useState(location.state.date);
  const [opciones, setOpciones] = useState(location.state.opciones);

  const [openDate, setOpenDate] = useState(false);
  const [date, setDate] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: 'selection'
    }
  ]);

  */

  return (
    <div className="listMain">
      <Navbar />
      <div className="listContainer">
        <div className="listWraper">

          {/*		Mobile searcher		
					<div className="tourListSearchMobile">
						<h1 className="lsTitle">Buscar</h1>
						<div className="lsItem">
							<label>Verificar disponibilidad:</label>
							<span
								className="checkin"
								 onClick={()=>setOpenDate(!openDate)}
							>
							{`${format(date[0].startDate, "dd/MM/yyyy")} al ${format(date[0].endDate, "dd/MM/yyyy")}`}
							</span>
							{openDate && (
								<DateRange 
									onChange={item=>setDate([item.selection])}
									className="lsDate"
									minDate={new Date()}
									ranges={date}
								/>
							)}
						</div>
						<div className="lsItem">
							<label>Selecciona tu aventura:</label>
							<div className="lsOptions">
								<div className="lsOptionItem">
									<input className="lsOptionInput" type="radio" />
									<label>
										Catedral de León
									</label>
								</div>
								<div className="lsOptionItem">
									<input className="lsOptionInput" type="radio" />
									<label>
										SandBoarding
									</label>
								</div>
								<span>
									Precios desde 2,160 NIO
								</span>
							</div>
						</div>
						<button>
							<b>
								Buscar
							</b>
						</button>
					</div>

*/}
          {/*		Mobile searcher		

					<div className="tourListSearch">
						<h1 className="lsTitle">Buscar</h1>
						<div className="lsItem">
							<label>Verificar disponibilidad:</label>
							<span
								className="checkin"
								 onClick={()=>setOpenDate(!openDate)}
							>
							{`${format(date[0].startDate, "dd/MM/yyyy")} al ${format(date[0].endDate, "dd/MM/yyyy")}`}
							</span>
							{openDate && (
								<DateRange 
									onChange={item=>setDate([item.selection])}
									className="lsDate"
									minDate={new Date()}
									ranges={date}
								/>
							)}
						</div>
						<div className="lsItem">
							<label>Selecciona tu aventura:</label>
							<div className="lsOptions">
								<div className="lsOptionItem">
									<input className="lsOptionInput" type="radio" />
									<label>
										Catedral de León
									</label>
								</div>
								<div className="lsOptionItem">
									<input className="lsOptionInput" type="radio" />
									<label>
										SandBoarding
									</label>
								</div>
								<span>
									Precios desde 2,160 NIO
								</span>
							</div>
						</div>
						<button>
							<b>
								Buscar
							</b>
						</button>
					</div>
*/}
          <section className="listResult">
            <VolcanoBoardingCard />
            {/* <LeonTourCity /> */}
            <CerroNegroAsososcaTour />
            <TelicaVolcanTour />
          </section>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default ToursList;
