import { BrowserRouter, Routes, Route, } from "react-router-dom";
import Home from "./pages/home/Home";
import HotelList from "./pages/hotelList/HotelList";
// import Hotel from "./pages/hotel/Hotel";
import Buffet from "./pages/buffet/Buffet";
import ScrollTop from "./components/ScrollTop";
import Login from "./pages/login/Login";
import { useContext } from "react";
import { DarkModeContext } from './context/darkModeContext';
import Tours from "./pages/tours/Tours";
import ToursList from "./pages/toursList/ToursList";
import Contacto from "./pages/contacto/Contacto";
import UbicacionPage from "./pages/UbicacionPage/UbicacionPage";


const App = () => {

  const { darkMode } = useContext(DarkModeContext)

  return (
    <div className={darkMode ? "app dark" : "app"}>
      <BrowserRouter>
        <ScrollTop>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/rooms" element={<HotelList />} />
            <Route path="/login" element={<Login />} />
            <Route path="/buffet" element={<Buffet />} />
            <Route path="/tours" element={<Tours />} />
            <Route path="/tourList" element={<ToursList />} />
            <Route path="/ubicacion" element={<UbicacionPage />} />
            <Route path="/contacto" element={<Contacto />} />
          </Routes>
        </ScrollTop>
      </BrowserRouter>
    </div>
  );
}

export default App;
