import img0 from '../../media/images/MULTIMEDIA-HOTEL-SAN-JUAN-LEON/TURISMO/volcan_cerroNegro_00.jpeg';
import img1 from '../../media/images/MULTIMEDIA-HOTEL-SAN-JUAN-LEON/TURISMO/volcan_cerroNegro_01.jpeg';

export const fotosTurismo = [
  {
    id: 1,
    img: img0
  },
  {
    id: 2,
    img: img1
  }
]
