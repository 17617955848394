import "../../searchItem/searchItem.css";
import { FaWifi, FaSnowflake, FaShower, FaSmokingBan } from "react-icons/fa";
import { MdFreeBreakfast } from "react-icons/md";
import { fotosUnipersonal } from "../../../assets/dataCuartoFotos";
import Slideshow from "../../slideshows/Slideshow";
import { withTranslation } from 'react-i18next';

import React, { Component } from "react";
import "../../../pages/hotel/hotel.css";
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import es from "date-fns/locale/es";
import Swal from "sweetalert2";
import TimerMixin from "react-timer-mixin";
import axios from "axios";
// import moment from "moment-timezone";
registerLocale("es", es);


class CardUnipersonal extends Component {

  constructor(props) {
    super(props);
    this.state = {

      NPMBRE_REC: "",
      CORREO_REC: "",
      TELEFONO_REC: "",
      MENSAJE_REC: "",
      NUMERO_PERS_REC: "1",
      COD_EMPRESA_REC: "1",
      COD_SUCURSAL_REC: "1",
      TIPO_RESERVA: "1",
      RESERVA_REC: "1",

      showReservar: false,

      fecha_1: new Date(),//GET
      fecha_2: new Date(),//GET
      HORA: new Date(),//GET

      HOY: new Date(),
    };

    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.reestriccion_telefono = this.reestriccion_telefono.bind(this);
    this.toggleAbrirReservar = this.toggleAbrirReservar.bind(this)
    this.cerrarReservar = this.cerrarReservar.bind(this)

  }
  onChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }


  onChange2 = (fecha_1_1) => {
    this.setState({ fecha_1: fecha_1_1 });
  };
  onChange3 = (fecha_2_2) => {
    this.setState({ fecha_2: fecha_2_2 });
  };

  onChange4 = (hora1) => {
    this.setState({ HORA: hora1 });
  };

  toggleAbrirReservar(event) {
    event.preventDefault()
    this.setState({
      showReservar: !this.state.showReservar
    })
  }
  cerrarReservar(event) {
    event.preventDefault()
    this.setState({
      showReservar: false
    })

  }

  registro = (newCliente) => {


let CHECK_INT = new Date(newCliente.CHECK_INT);
let dd1 = CHECK_INT.getDate();
let mm1 = CHECK_INT.getMonth() + 1;
let yyyy1 = CHECK_INT.getFullYear();

newCliente.CHECK_INT = yyyy1 + "-" + mm1 + "-" + dd1;

let CHECK_OUT = new Date(newCliente.CHECK_OUT);
let dd2 = CHECK_OUT.getDate();
let mm2 = CHECK_OUT.getMonth() + 1;
let yyyy2 = CHECK_OUT.getFullYear();

newCliente.CHECK_OUT = yyyy2 + "-" + mm2 + "-" + dd2;

    return axios
      .post("https://hotelsanjuan.herokuapp.com/reserva/registro", {
        COD_EMPRESA: newCliente.COD_EMPRESA,
        COD_SUCURSAL: newCliente.COD_SUCURSAL,
        NOMBRE: newCliente.NOMBRE,
        CORREO: newCliente.CORREO,
        TELEFONO: newCliente.TELEFONO,
        CHECK_INT: newCliente.CHECK_INT,
        CHECK_OUT: newCliente.CHECK_OUT,
        HORA: newCliente.HORA,
        NO_PERSONAS: newCliente.NO_PERSONAS,
        MENSAJE: newCliente.MENSAJE,
        TIPO_RESERVA: newCliente.TIPO_RESERVA,
        RESERVA: newCliente.RESERVA,
        ADICIONADO_POR: newCliente.ADICIONADO_POR,

      })
      .then((response) => {
        // Swal.fire(this.props.t('swal1.registroReserva'),
        //   response.data.mensaje,
        //   response.data.alerta);

        var tiempo = "300";
        TimerMixin.setTimeout(() => {
          if (response.data.alerta === "error") {

            Swal.fire(
              this.props.t('swal1.registroReserva'),
              this.props.t('swal1.verifiqueDatos'),
              "error"
            );

          } else {
            Swal.fire(
              this.props.t('swal1.registroReserva'),
              this.props.t('swal1.registroSatisfactorio'),
              "success"
            );
            this.setState({
              NPMBRE_REC: "",
              CORREO_REC: "",
              TELEFONO_REC: "",
              fecha_1: new Date(),
              fecha_2: new Date(),
              HORA: new Date(),
              MENSAJE_REC: "",
              NUMERO_PERS_REC: "1",

            })
          }

        }, tiempo);
      })
      .then((res) => {
        return res;
      })
      .catch((err) => {
        console.log(err)
      });
  };

  onSubmit(e) {
    var tiempo = "600";
    if (this.state.NPMBRE_REC === "") {
      e.preventDefault();
      Swal.fire(
        this.props.t('swal1.registroReserva'),
        this.props.t('swal1.ingreseNombre'),
        "error"
      );
    }
    else if (this.state.CORREO_REC === "") {
      e.preventDefault();
      Swal.fire(this.props.t('swal1.registroReserva'), this.props.t('swal1.ingresarCorreo'), "error");
    }
    else if (this.state.TELEFONO_REC === "") {
      e.preventDefault();
      Swal.fire(this.props.t('swal1.registroReserva'), this.props.t('swal1.ingresarTelefono'), "error");
    } else if (this.state.NUMERO_PERS_REC === "") {
      e.preventDefault();
      Swal.fire(this.props.t('swal1.registroReserva'), this.props.t('swal1.ingresaarPersonas'), "error");
    } else if (this.state.fecha_1 > this.state.fecha_2) {
      e.preventDefault();
      Swal.fire(
        this.props.t('swal1.registroReserva'),
        "Por Favor Verifique La Fecha De Salida",
        "error"
      );
    } else if (this.state.fecha_1 === null) {
      e.preventDefault();
      Swal.fire(
        this.props.t('swal1.registroReserva'),
        this.props.t('swal1.verifiqueCheckIn'),
        "error"
      );
    } else if (this.state.fecha_2 === null) {
      e.preventDefault();
      Swal.fire(
        this.props.t('swal1.registroReserva'),
        this.props.t('swal1.verifiqueCheckOut'),
        "error"
      );
    } else if (this.state.HORA === null) {
      e.preventDefault();
      Swal.fire(
        this.props.t('swal1.registroReserva'),
        this.props.t('swal1.verifiqueHoraLLegada'),
        "error"
      );
    } else {
      e.preventDefault();
      Swal.fire({
        title: this.props.t('swal1.realizarReserva'),
        showDenyButton: true,
        // showCancelButton: true,
        confirmButtonColor: '#008BD9',
        confirmButtonText: this.props.t('swal1.confirmBTN'),
        denyButtonText: this.props.t('swal1.denyBTN'),
      }).then((result) => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
          // e.preventDefault();
          TimerMixin.setTimeout(() => {
            let timerInterval
            Swal.fire({
              title: this.props.t('swal1.registroReserva'),
              html: this.props.t('swal1.enviandoDatos'),
              timer: 2000000,
              timerProgressBar: true,
              didOpen: () => {
                Swal.showLoading()
                try {
                  const b = Swal.getHtmlContainer().querySelector('b')
                  timerInterval = setInterval(() => {
                    b.textContent = Swal.getTimerLeft()
                  }, 2000000)
                } catch (error) {

                }
              },
              willClose: () => {
                clearInterval(timerInterval)
              }
            }).then((result) => {
              /* Read more about handling dismissals below */
              if (result.dismiss === Swal.DismissReason.timer) {
                console.log('I was closed by the timer')
              }
            })
            const newCliente = {
              COD_EMPRESA: this.state.COD_EMPRESA_REC,
              COD_SUCURSAL: this.state.COD_SUCURSAL_REC,
              NOMBRE: this.state.NPMBRE_REC,
              CORREO: this.state.CORREO_REC,
              TELEFONO: this.state.TELEFONO_REC,
              CHECK_INT: this.state.fecha_1,
              CHECK_OUT: this.state.fecha_2,
              HORA: this.state.HORA,
              NO_PERSONAS: this.state.NUMERO_PERS_REC,
              MENSAJE: this.state.MENSAJE_REC,
              TIPO_RESERVA: this.state.TIPO_RESERVA,
              RESERVA: this.state.RESERVA_REC,
              ADICIONADO_POR: "HUESPED",
            };

            this.registro(newCliente).then((res) => { });

            this.setState({
              showReservar: false
            })

          }, tiempo);
          // Swal.fire('Registrado!', '', 'success')
        } else if (result.isDenied) {
          Swal.fire({
            title: this.props.t('swal1.registroCancelado'),
            confirmButtonColor: '#008BD9',
          }, "Registro cancelado", "", "info");
        }
      });
    } // here else cerrare
  }

  reestriccion_telefono(e) {
    if (this.state.TELEFONO_REC !== "") {
      if (this.state.TELEFONO_REC.length < 8) {
        e.preventDefault();
        Swal.fire(
          this.props.t('swal1.registroReserva'),
          this.props.t('swal1.errorNumero'),
          "error"
        );
      } else if (this.state.TELEFONO_REC.length > 8) {
        e.preventDefault();
        Swal.fire(
          this.props.t('swal1.registroReserva'),
          this.props.t('swal1.errorNumero'),
          "error"
        );
      }
    }
  }


  render() {
    const { showReservar } = this.state
    const { t } = this.props;
    return (
      <>
        {showReservar === true ?
          <div className="reservarContainer" >
            <div className="reservarWrapper">

              <h2
                className="reservaMainTitle"
                htmlFor="NPMBRE_REC">
                {t('Book.bookNow')}
              </h2>
              <form className="formReservas">

                <div
                  htmlFor="NPMBRE_REC"
                  className="formInput">
                  <label>
                    {t('Book.fullName')}
                  </label>
                  <input
                    type="NPMBRE_REC"
                    name="NPMBRE_REC"
                    value={this.state.NPMBRE_REC}
                    onChange={this.onChange}
                    autoComplete="off"
                  />
                </div>

                <div
                  htmlFor="CORREO_REC"
                  className="formInput">
                  <label>
                    {t('Book.email')}
                  </label>
                  <input
                    type="CORREO_REC"
                    name="CORREO_REC"
                    value={this.state.CORREO_REC}
                    onChange={this.onChange}
                    autoComplete="off"
                  />
                </div>

                <div
                  htmlFor="TELEFONO_REC"
                  className="formInput">
                  <label>
                    {t('Book.phone')}
                  </label>
                  <input
                    type="TELEFONO_REC"
                    name="TELEFONO_REC"
                    value={this.state.TELEFONO_REC}
                    onChange={this.onChange}
                    // onBlur={this.reestriccion_telefono}
                    autoComplete="off"
                  />
                </div>


                <div
                  htmlFor="NUMERO_PERS_REC"
                  className="formInput">
                  <label>
                    {t('Book.people')}
                  </label>
                  <select
                    name="NUMERO_PERS_REC"
                    value={this.state.NUMERO_PERS_REC}
                    onChange={this.onChange}
                  >

                    <option>1</option>
                    {/* <option>2</option>
<option>3</option>
<option>4</option> */}
                  </select>
                </div>

                <div
                  htmlFor="fecha_1"
                  className="formInput">
                  <label>
                    {t('Book.chekin')}
                  </label>
                  <DatePicker
                    minDate={this.state.HOY}
                    selected={this.state.fecha_1}
                    onChange={this.onChange2}
                    locale="es"
                    className="formInputFecha"
                    dateFormat="dd '/' M '/' yyyy"
                  />
                </div>

                <div
                  htmlFor="fecha_1"
                  className="formInput">
                  <label>
                    {t('Book.chekout')}
                  </label>
                  <DatePicker
                    minDate={this.state.HOY}
                    selected={this.state.fecha_2}
                    onChange={this.onChange3}
                    locale="es"
                    className="formInputFecha"
                    dateFormat="dd '/' M '/' yyyy"
                  />
                </div>
                <div
                  htmlFor="fecha_1"
                  className="formInput">
                  <label>
                    {t('Book.time')}
                  </label>
                  <DatePicker
                    className="formInputFecha"
                    selected={this.state.HORA}
                    onChange={this.onChange4}
                    showTimeSelect
                    showTimeSelectOnly
                    timeIntervals={15}
                    timeCaption="Time"
                    dateFormat="h:mm aa"
                  />
                </div>
                <div
                  htmlFor="MENSAJE_REC"
                  className="formInput">
                  <label>
                    {t('Book.message')}
                  </label>
                  <textarea
                    style={{ width: '100%' }}
                    type="MENSAJE_REC"
                    name="MENSAJE_REC"
                    value={this.state.MENSAJE_REC}
                    onChange={this.onChange}
                    autoComplete="off"
                  />
                </div>

                <div className="formBtns">
                  <button
                    type="button"
                    className="btnReservaYaAceptar"
                    onClick={this.onSubmit}>
                    {t('Book.send')}
                  </button>
                  <button
                    type="button"
                    className="btnReservaYaCancelar"
                    onClick={this.cerrarReservar}>
                    {t('Book.cancel')}
                  </button>
                </div>

              </form>

            </div>
          </div>
          : null}


        <article className="searchItem">
          <Slideshow images={fotosUnipersonal} />
          <div className="siColumn">
            <div className="siDesc">
              <h1 className="siTitle">
                {t('RoomUnipersonal.roomTitle')}
              </h1>
              <div className="siContenido">
                <span className="siCamas">
                  {t("RoomUnipersonal.bedType")}
                </span>
                <span className="siCapacidadxPersona">
                  {t("RoomUnipersonal.capacity")}
                </span>
                <span className="siDesayuno">
                  {t("RoomUnipersonal.breakfast")}
                </span>
                {/*
          <span className="siAlertRoom"><AiOutlineWarning /> Solo queda 1 habitación en nuestra web</span>
*/}
              </div>
              <div className="siCancelacion">
              </div>
              <div className="siServicios">
                <div className="siServiciosLeft">
                  <p>
                    <FaWifi className="iconos" />
                    {t("RoomDetails.wifi")}
                  </p>
                  <p>
                    <FaSnowflake className="iconos" />
                    {t("RoomDetails.air")}
                  </p>
                  <p>
                    <FaShower className="iconos" />
                    {t("RoomDetails.bathroom")}
                  </p>
                </div>
                <div className="siServiciosRight">
                  <p><MdFreeBreakfast className="iconos" />
                    {t("RoomDetails.breakfast")}
                  </p>
                  <p><FaSmokingBan className="iconos" />
                    {t("RoomDetails.smoking")}
                  </p>
                </div>
              </div>
            </div>
            <div className="siDetails">
              <div className="siRating">
              </div>
              <div className="siDetailsTexts">
                <div className="preciosRoom">
                  <h5>
                    {t('TourCerroNegroAsososca.prices')}
                  </h5>
                  <div className="preNac" >
                    <span className="stPrice">
                      $30
                    </span>
                  </div>

                </div>
                {/*
					<span className="siTaxOp">Incluye iva</span>
					*/}
                <button
                  onClick={this.toggleAbrirReservar}
                  className="siCheckBtn">
                  {t("Book.bookNow")}
                </button>
              </div>
            </div>
          </div>
        </article>
      </>
    );
  };
};

export default withTranslation()(CardUnipersonal);
