import React from "react";
import "./header.scss";
//import { faCalendarDays, faPerson} from "@fortawesome/free-solid-svg-icons";
//import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
//import { DateRange } from "react-date-range";
//import { useState } from "react";
import 'react-date-range/dist/styles.css';// main css file
import 'react-date-range/dist/theme/default.css'; // theme css file
//import { format } from "date-fns";
//import {useNavigate} from "react-router-dom";
import { Link } from "react-router-dom";
import { useTranslation, withTranslation } from "react-i18next";

const Header = ({ type }) => {

  /*
  const [openDate, setOpenDate] = useState(false);
  const [abrirOpciones, setAbrirOpciones] = useState(false);
  const [opciones, setOpciones] = useState({
    adultos:1,
    ninos:0,
    cuarto:1,
  });

  const [date, setDate] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: 'selection'
    }
  ]);

  const navigate = useNavigate()

  const handleOption = (nombre, operacion) =>{
    setOpciones(prev=>{return {
      ...prev, [nombre]: operacion === "aumentar" ? opciones[nombre] +1 : opciones[nombre] -1,
    }})
  }

  const handleSearch = () =>{
    navigate("/rooms")
  }
  */

  const { t } = useTranslation();

  return (
    <div className="header">
      <div className={type === "list" ? "headerContainer listMode" : "headerContainer"}>
        <h1 className="headerTitle">
          {t("header.heading1")}
        </h1>
        <p className="headerDesc">
          {t("header.heading2")}
        </p>
        <Link to="/rooms">
          <button className="headerBtn">
            {t("header.headingBtn")}
          </button>
        </Link>
        {/*
				<div className="headerSearch">
					<div className="headerSearchItem">
						<FontAwesomeIcon icon={faCalendarDays} className="headerIcon" onClick={()=>setOpenDate(!openDate)} />
						<span onClick={()=>setOpenDate(!openDate)} className="headerSearchText">{`Check in ${format(date[0].startDate, "dd/MM/yyyy")} Check Out ${format(date[0].endDate, "dd/MM/yyyy")}`}</span>
						{openDate &&
						<DateRange
							editableDateInputs={true}
							onChange={item => setDate([item.selection])}
							moveRangeOnFirstSelection={false}
							ranges={date}
							className="date"
							minDate={new Date()}
						/>}
					</div>
					<div className="headerSearchItem">
						<FontAwesomeIcon icon={faPerson} className="headerIcon"/>
						<span 
							onClick={()=>setAbrirOpciones(!abrirOpciones)} 
							className="headerSearchText">{`${opciones.adultos} adultos - ${opciones.ninos} niños - ${opciones.cuarto} cuarto`}
						</span>
						{abrirOpciones &&
						<div className="options">
							<div className="optionItem">
								<span className="optionText">
									Adultos
								</span>
								<div className="optionCounter">
									<button 
										disabled={opciones.adultos <= 1}
										className="optionCounterBtn"
										onClick={()=>handleOption("adultos","disminuir")}>
										-
									</button>
									<span className="optionCounterNumber">
										{opciones.adultos}
									</span>
									<button 
										className="optionCounterBtn"
										onClick={()=>handleOption("adultos","aumentar")}>
										+
									</button>
								</div>
							</div>
							<div className="optionItem">
							<span className="optionText">
							Niños
							</span>
								<div className="optionCounter">
									<button 
										disabled={opciones.ninos <= 0}
										className="optionCounterBtn"
										onClick={()=>handleOption("ninos","disminuir")}>
										-
									</button>
									<span className="optionCounterNumber">
										{opciones.ninos}
									</span>
									<button 
										className="optionCounterBtn"
										onClick={()=>handleOption("ninos","aumentar")}>
										+
									</button>
								</div>
							</div>
							<div className="optionItem">
							<span className="optionText">
							Cuarto
							</span>
								<div className="optionCounter">
									<button 
										disabled={opciones.cuarto <= 1}
										className="optionCounterBtn"
										onClick={()=>handleOption("cuarto","disminuir")}>
										-
									</button>
									<span className="optionCounterNumber">
										{opciones.cuarto}
									</span>
									<button 
										className="optionCounterBtn"
										onClick={()=>handleOption("cuarto","aumentar")}>
										+
									</button>
								</div>
							</div>
						</div>
						}
		</div>
			<div className="headerSearchItem">
				<button 
					className="headerBtnSearch"
					onClick={handleSearch}>
					Buscar
				</button>
			</div>
		</div>
			*/}
      </div>
    </div>
  );
};

export default withTranslation()(Header);
